<template>
	<div>
		<Header v-bind="header" @goHome="() => $emit('goHome')"/>
		<div class="w-full flex justify-center px-4 sm:px-0">
			<div class="my-24 sm:my-10 max-w-7xl">
				<PolicyHeader v-bind="policyHeader"/>
				<template v-for="item in policyItems">
					<PolicyItem v-bind="item"/>
				</template>
			</div>
		</div>
		<Footer v-bind="footer"/>
	</div>

</template>

<script>
import Header from '../../organisms/Headers/SimpleWithNavigation/SimpleWithNavigation.vue'
import PolicyHeader from '../../organisms/TermsAndPolicy/Header/Header.vue'
import PolicyItem from '../../organisms/TermsAndPolicy/ContentItem/Item.vue'
import Footer from '../../organisms/Footers/SimpleWithSocialIcons/SimpleWithSocialIcons.vue'

/**
 - Use it to show a Home template
 **/
export default {
	components: {
		Header,
		Footer,
		PolicyHeader,
		PolicyItem,
	},
	props: {
		header: {
			type: Object,
			default: () => {
			},
		},
		policyHeader: {
			type: Object,
			default: () => {
			}
		},
		policyItems: {
			type: Array,
			default: () => []
		},
		footer: {
			type: Object,
			default: () => {
			}
		}
	}
};
</script>
